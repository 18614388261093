.logo-container {
  margin-top: 40px;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logo-image {
  height: 110px;
  object-fit: contain;
}

@media (max-width: 600px) { 
  img.logo-image {
    height: 55px;
  }

  .logo-container {
    margin-top: 15px;
    margin-bottom: -20px;
  }
}