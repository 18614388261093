.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.95);  
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 20px;
  text-align: center;
  display: none;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  z-index: 10;
  font-size: 20pt;
  color: #eb3224;
}

.loader.interim {
  display: flex;
  opacity: 0;
}

.loader.visible {
  display: flex;
  opacity: 1;
}

.loader > img {
  width: 45%;
  max-width: 350px;
}

@media (max-width: 600px) { 
  .loader {
    font-size: 14pt;
  }
}
