.footer {
  margin-bottom: 5%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
  width: 60px;
}

.footer img {
  width: 60px;
  height: auto;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.footer.inverted {
  filter: invert(1);
}