.full-screen {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.interactive {
  cursor: pointer;
}

.dob-input {
  display: flex;  
}

.dob-input > select {
  margin-right: 10px;
}

@media (max-width: 500px) {  
  .dob-input {
    display: flex;  
    flex-direction: column;
  }
  .dob-input > select {
    margin-bottom: 20px;
  }    
}

.data-section-container {
  margin: 20px;
}

.data-section-container.submit {
  margin-top: 35px;
}

.data-label {
  margin-bottom: 3px;
}

.button {
  padding: 10px 15px;
  border: 1px solid black;
  border-radius: 2px;
  width: 150px;
  text-align: center;
  font-size: 16pt;
}

.text-container {
  max-width: 1200px;
  text-align: center;
}

.language-selection {
  display: flex;
}

.language-button {
  margin: 10px;
}