@import url(https://fonts.googleapis.com/css2?family=Averia+Serif+Libre:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap);
*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  --select-border: #777;
  --select-focus: blue;
  --select-arrow: var(--select-border);
}

select, input {
  -webkit-appearance: none;
          appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  outline: none;
}

select::-ms-expand {
  display: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100vw;
  overflow: hidden;  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.select {
  width: 100%;
  min-width: 15ch;
  max-width: 30ch;
  border: 1px solid #777;
  border: 1px solid var(--select-border);
  border-radius: 0.25em;
  padding: 0.25em 0.5em;
  font-size: 1.25rem;
  line-height: 1.3;
  background-color: #fff;
  background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
  display: grid;
  grid-template-areas: "select";
}
.full-screen {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.interactive {
  cursor: pointer;
}

.dob-input {
  display: flex;  
}

.dob-input > select {
  margin-right: 10px;
}

@media (max-width: 500px) {  
  .dob-input {
    display: flex;  
    flex-direction: column;
  }
  .dob-input > select {
    margin-bottom: 20px;
  }    
}

.data-section-container {
  margin: 20px;
}

.data-section-container.submit {
  margin-top: 35px;
}

.data-label {
  margin-bottom: 3px;
}

.button {
  padding: 10px 15px;
  border: 1px solid black;
  border-radius: 2px;
  width: 150px;
  text-align: center;
  font-size: 16pt;
}

.text-container {
  max-width: 1200px;
  text-align: center;
}

.language-selection {
  display: flex;
}

.language-button {
  margin: 10px;
}
body {
  overflow: auto;
  background: #e5e5e5;
  font-family: 'AvenirRoman', sans-serif;
	font-weight: 400;
  font-size: 3rem;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.mobile-only {
  display: none;
}

.language-container {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 10;
  display: flex;
  flex-direction: row;
}

.language-button {
  border-radius: 40px;
  margin: 10px;
  padding: 8px 30px 5px 30px;
  background: black;
  border: 4px solid white;
  color: white;
  font-size: 16pt;
  text-align: center;  
  cursor: pointer;
  line-height: 1.3;
}

.underline {
  text-decoration: underline;
}

.all-container {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.all-container.hidden {
  opacity: 0;
}

.app-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content {
  position: relative;
  max-width: 800px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.section {
  margin-left: auto;
  margin-right: auto;
  font-size: 0.7em;
  text-align: center;  
}

.submit-container {
  margin-top: 5%;
  width: 80%;
  border-radius: 40px;
  border: 5px solid white;
  padding-top: 3%;
  padding-bottom: 3%;
  border-radius: 40px;
  font-size: 0.5em;
}

.information-container {
  margin-top: 10%;
  margin-bottom: 10%;
  position: relative;
  width: 80%;
  font-size: 0.6em;
}

.information-more {
  display: none;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.information-more.interim {
  display: block;
  opacity: 0;
}

.information-more.visible {
  display: block;
  opacity: 1;
}

.read-more {
  text-decoration: underline;
  margin-bottom: 10px;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.read-more.hidden {
  opacity: 0;
}

.text-instructions {
  text-align: center;
  margin: 5% 10%;
}

.text-instructions.error {
  color: #eb3224;
}

.code-input {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wristband-code-input {
  margin-bottom: 30px;
}

.nft-status-text-container {
  color: white;
}

.nft-status {
  max-width: 500px;
  min-width: 300px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 23px 75px 20px 75px;
  font-weight: bold;
  margin: 20px;
  text-align: center;
  font-size: 1.5rem;
  border: 5px solid black;
  border-radius: 40px;
  text-transform: uppercase;
}

@media (max-width: 600px) { 
  .nft-status {
    padding: 20px 20px 16px 20px;
    font-size: 12pt;
    margin: 20px 10px;
  }
}


.nft-status.green {
  background-color: rgb(128, 218, 162);
  text-transform: none;
  border: none;
  color: black;  
  max-width: 1000px;
}

.nft-confirmation-details {
  font-size: 12pt;
  margin-top: 10px;
  color: black;
  text-align: center;
  opacity: 0.6;
}

.nft-confirmation-details > a {
  color: black;
}

.nft-status.red {
  background-color: #eb3224;
  color: black;
  text-transform: none;
  border: none;
  max-width: 1000px; 
}

.nft-status.yellow {
  color: black;
  background: linear-gradient(271deg, #85ffe0, #eb3224, #81FB66);
  background-size: 400% 400%;

  -webkit-animation: minting-in-progress 5s ease infinite;
  animation: minting-in-progress 5s ease infinite;

  text-transform: none;
  border: none;
  max-width: 1000px; 
}

@-webkit-keyframes minting-in-progress {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@keyframes minting-in-progress {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}


.section-container {
  margin: 50px 0;
  width: 100%;
  text-align: center;
  border-radius: 40px;
  font-size: 1.5rem;
  border: 5px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nft-image-container {
  border: none;
  margin-bottom: 0;
  margin-top: 25px;
}

.not-ready-message-container {
  padding: 20px 40px 80px 40px;
  color: white;
  border: none;
}

.nft-container {
  width: 100%;
  max-width: 800px;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}

.mint-state-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.bold {
  font-weight: bold;
}

.nft-image-card {
  padding: 0 50px 25px 50px;
}

.nft-image-card > img {
  max-width: 90vw;
  width: 100%;
  /* max-height: calc(100vh - 500px); */
}

.nft-card-title {
  text-align: center;
  margin-bottom: 50px;
  text-transform: uppercase;
}

.mint-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px 40px 20px 40px;
}

.mint-container-title {
  text-transform: uppercase;
}

.mint-arrow {
  height: 100px;
  width: 2px;
  border-left: 2px solid black;
  -webkit-transform: translateX(1px);
          transform: translateX(1px); 
}

.instructions {
  margin-top: 20px;
  margin-bottom: 10px;
}

.instructions-step {
  margin-top: 20px;
  margin-bottom: 0;
}

.kukai-container {
  margin-top: 20px;
  margin-bottom: 20px;
}

.kukai-badge {
  width: 150px;
  background-color: white;
  padding: 10px 20px;
  border-radius: 15px;
}

.kukai-badge > img {
  width: 100%;
  margin-top: 5px;
}

.wallet-input-container {
  margin-top: 20px;
  text-align: center;
  margin-bottom: 20px;
}

.wallet-input-container > .instructions {
  margin-bottom: 10px;
  font-weight: bold;
}

.wallet-input-container > input {
  width: 450px;
  max-width: 450px;
  text-align: center;
}

.mint-button-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.nft-confirmation-details {
  margin-top: 10px;
  margin-bottom: 30px;
}

.submit-code {
  margin-top: 5%;
}

.submit-button {
  display:inline-block;
  border-radius:30px;
  padding:20px 40px 20px 40px;
  background: #eb3224;
  background-image: url(/static/media/submit.6eddbe7c.svg);  
  background-repeat: no-repeat;
  background-size: 70px 70px;
  background-position: center;
  box-shadow: 0px 0px 5px rgb(150,150,150);
  height: 80px;
  width: 120px;
  opacity: 1;
  transition: opacity 0.25s ease-in-out, -webkit-filter 0.5s ease-in-out;
  transition: opacity 0.25s ease-in-out, filter 0.5s ease-in-out;
  transition: opacity 0.25s ease-in-out, filter 0.5s ease-in-out, -webkit-filter 0.5s ease-in-out;
  margin-top: 50px;
  -webkit-filter: grayscale(0);
          filter: grayscale(0);
}

.scroll-down {
  background-image: url(/static/media/scroll-down.a58a6170.svg);
  height: 40px;
  width: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3%;
  margin-bottom: 3% !important;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0;
}

.submit-button:hover {
  opacity: 0.8;
}

.submit-button.disabled {
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
  opacity: 0.5;
}

.submit-button.disabled:hover {
  opacity: 1;
}

.link-disabled {
  pointer-events: none;
}

input.code-input-new {
  border: 2px solid black;
  border-radius: 15px;
  width: 60px;
  height: 60px;  
  background: white;
  text-align: center;
  padding: 0;
  font-size: 19pt;
  padding-top: 5px;
  margin-right: 20px;
}

input.code-input-new:last-child {
  margin-right: 0;
}

input.code-input-new.filled {
  border: 2px solid #eb3224;
}

.wallet-button {
  border-radius:30px;
  padding:21px 40px 18px 40px;
  background: #eb3224;
  box-shadow: 0px 0px 5px rgb(150,150,150);
  color: white;
  min-width: 350px;
  margin-bottom: 20px;
}

.wallet-button.disabled {
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
}

.wallet-button.mint {
  min-width: auto;
  width: 200px;
  padding: 13px 40px 10px 40px;
}

.connected-text {
  margin-top: 20px;
}

.connected-text-wallet {
  font-family: monospace;
  margin-bottom: 30px;
}

.wallet-address-container {
  /* opacity: 0.5; */
  /* font-size: 15pt; */
  /* margin-top: -20px; */
}

.mint-now-container {
  display: none;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.mint-now-container.interim {
  display: block;
}

.mint-now-container.visible {
  display: block;
  opacity: 1;
}

@media (max-width: 600px) { 
  .mobile-only {
    display: auto;
  }

  .no-mobile {
    display: none;
  }

  .instructions {
    max-width: 90%;
  }
  
  .mint-button-container {
    margin-top: 0;
  }

  .section-container {
    font-size: 14pt;
  }

  .connected-text-wallet {
    margin-bottom: 15px;
  }

  .mint-container {
    padding: 10px;
    padding-top: 5px;
    margin-top: 2px;
    font-size: 14pt;
  }

  .wallet-button {
    padding: 15px 20px 13px 20px;
    min-width: 210px;
    font-size: 11pt;
    margin-bottom: 5px;
  }

  .wallet-button-connect {
    /* padding: 10px 10px 8px 10px; */
    padding: 0 10px;
    min-width: 150px;
    height: 50px;
    line-height: 50px;
    margin-bottom: 5px;
    margin-left: 7px;
    margin-right: 7px;
  }

  .wallet-button-connect > span {
    display: inline-block;
    vertical-align: middle;
    line-height: 1.3;
    max-width: 100px;
    padding-top: 1px;
  }

  .mint-buttons-container {
    display: flex;
    flex-direction: row;
    margin-top: 6px;
  }

  .nft-image-card {
    padding: 0;
  }

  .submit-container {
    font-size: 14pt;
  }

  input.code-input-new {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }

  .instructions-step {
    margin-top: 8px;
  }

  .submit-button {
    height: 60px;
    width: 100px;
    border-radius: 20px;
    background-size: 40px 40px;
  }

  .information-container {
    font-size: 14pt;
  }

  .language-container {    
    top: 20px;
    right: 10px;
    z-index: 10;
    display: flex;
    flex-direction: column;
  }
  
  .language-button {
    margin: 3px;
    padding: 9px 20px 6px 20px;
    font-size: 12pt;
  }

  .nft-container {
    padding: 0 7px;
  }
 
  .nft-image-container {
    margin-top: 0;
  }

  .nft-confirmation-details {
    margin-bottom: 10px;
    max-width: 90%;
    -webkit-transform: translateX(5.5%);
            transform: translateX(5.5%);
    text-align: center;
    font-size: 11pt;
  }

  .nft-confirmation-details > a {
    line-height: 1.5;
  }

  .nft-image-card > img {
    max-height: calc(1vh * 100 - 262px);
    max-height: calc(var(--vh, 1vh) * 100 - 262px);
    /* max-width: auto; */
    /* width: auto; */
    object-fit: contain;
  }  
}

.footer {
  margin-bottom: 5%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
  width: 60px;
}

.footer img {
  width: 60px;
  height: auto;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.footer.inverted {
  -webkit-filter: invert(1);
          filter: invert(1);
}
.logo-container {
  margin-top: 40px;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logo-image {
  height: 110px;
  object-fit: contain;
}

@media (max-width: 600px) { 
  img.logo-image {
    height: 55px;
  }

  .logo-container {
    margin-top: 15px;
    margin-bottom: -20px;
  }
}
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.95);  
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 20px;
  text-align: center;
  display: none;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  z-index: 10;
  font-size: 20pt;
  color: #eb3224;
}

.loader.interim {
  display: flex;
  opacity: 0;
}

.loader.visible {
  display: flex;
  opacity: 1;
}

.loader > img {
  width: 45%;
  max-width: 350px;
}

@media (max-width: 600px) { 
  .loader {
    font-size: 14pt;
  }
}

.background-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.background-image > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
